import React, { FC } from 'react';
import { FluidGrid } from '@design-system/fluid-grid';
import { PageBlock } from '@design-system/page-block';

import { SalsifyColumnsTextProps } from './models';

const SalsifyColumnsText: FC<SalsifyColumnsTextProps> = ({
  fluidGrid,
  salsifyTextColumns,
  pageBlock,
}) => (
  <PageBlock {...pageBlock} className="salsify-columns-text">
    <FluidGrid {...fluidGrid}>
      {salsifyTextColumns.map((column) => (
        <PageBlock key={column.heading?.headline} {...column} />
      ))}
    </FluidGrid>
  </PageBlock>
);

export default SalsifyColumnsText;
